import React, { Component } from "react";
import Layout from "./components/Layout/";
import {
  Route,
  Switch,
  BrowserRouter as Router,
  withRouter
} from "react-router-dom";

// Import Css
import "./Apps.scss";
import "./css/materialdesignicons.min.css";

// Include Routes
import routes from "./routes";

// Root Include
const Root = React.lazy(() => import("./pages/CloudHosting"));
const PageComingSoon = React.lazy(() => import("./pages/PageComingSoon"));
const PageError = React.lazy(() => import("./pages/PageError"));
const PageMaintenance = React.lazy(() => import("./pages/PageMaintenance"));

const PageSignup = React.lazy(() => import("./pages/PageSignup"));

function withLayout(WrappedComponent) {
  // ...and returns another component...
  return class extends React.Component {
    render() {
      return (
        <Layout>
          <WrappedComponent></WrappedComponent>
        </Layout>
      );
    }
  };
}

class App extends Component {
  render() {
    return (
      <React.Fragment>
        <Router>
          <React.Suspense fallback={<div></div>}>
            <Switch>
              {routes.map((route, idx) => (
                <Route
                  path={route.path}
                  component={withLayout(route.component)}
                  key={idx}
                />
              ))}
              <Route path="/page-comingsoon" component={PageComingSoon} />
              {/*
              <Route path="/page-error" component={PageError} />
              <Route path="/page-maintenance" component={PageMaintenance} />
              />
              */}
              <Route path="/" component={Root} />
            </Switch>
          </React.Suspense>
        </Router>
      </React.Fragment>
    );
  }
}

export default withRouter(App);
