import React from "react";

// Import all components
const CloudHosting = React.lazy(() => import("./pages/CloudHosting/index"));
const Contacto = React.lazy(() => import("./pages/CloudHosting/Contact"));

const routes = [
  // public Routes
  { path: "/contacto", component: Contacto },
  { path: "/index", component: CloudHosting },
  { path: "/", component: CloudHosting }
];

export default routes;
