import React, { Component } from "react";
import { Link } from "react-router-dom";

// import images
import americanEx from "../../images/payments/american-ex.png";
import discover from "../../images/payments/discover.png";
import masterCard from "../../images/payments/master-card.png";
import paypal from "../../images/payments/paypal.png";
import visa from "../../images/payments/visa.png";
import logo from "../../images/hosting/logo.png";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <footer className="footer">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
                <Link className="logo-footer" to="/index">
                  <img alt="logo" id="logo" src={logo} height="70px" />
                </Link>
                <p className="mt-4">Encuentranos en nuestras redes sociales</p>
                <ul className="list-unstyled social-icon social mb-0 mt-4">
                  <li className="list-inline-item">
                    <a
                      href="https://www.facebook.com/develocat"
                      className="rounded mr-1"
                      target="_blank"
                    >
                      <i className="mdi mdi-facebook" title="Facebook"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      href="https://www.instagram.com/develo.cat/"
                      className="rounded mr-1"
                      target="_blank"
                    >
                      <i className="mdi mdi-instagram" title="Instagram"></i>
                    </a>
                  </li>
                </ul>
              </div>

              <div className="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <h4 className="text-light footer-head">Links</h4>
                <ul className="list-unstyled footer-list mt-4">
                  <li>
                    <Link to="/" className="text-foot">
                      <i className="mdi mdi-chevron-right mr-1"></i> Inicio
                    </Link>
                  </li>
                  {/*<li>
                    <Link to="/desarrollo-web" className="text-foot">
                      <i className="mdi mdi-chevron-right mr-1"></i> Desarrollo
                      Web
                    </Link>
                  </li>
                  <li>
                    <Link to="/portafolio" className="text-foot">
                      <i className="mdi mdi-chevron-right mr-1"></i> Portafolio
                    </Link>
                  </li>*/}
                  <li>
                    <Link to="/contacto" className="text-foot">
                      <i className="mdi mdi-chevron-right mr-1"></i> Contacto
                    </Link>
                  </li>
                </ul>
              </div>

              {/*<div className="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <h4 className="text-light footer-head">Planes</h4>
                <ul className="list-unstyled footer-list mt-4">
                  <li>
                    <Link to="/plan-basico" className="text-foot">
                      <i className="mdi mdi-chevron-right mr-1"></i> Básico
                    </Link>
                  </li>
                  <li>
                    <Link to="/plan-a-medida" className="text-foot">
                      <i className="mdi mdi-chevron-right mr-1"></i> A Medida
                    </Link>
                  </li>
                </ul>
                </div>*/}
            </div>
          </div>
        </footer>
        <hr />
        <footer className="footer footer-bar">
          <div className="container text-center">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <div className="text-sm-left">
                  <p className="mb-0">
                    © {new Date().getFullYear()} DeveloCat.cl
                  </p>
                </div>
              </div>
              {/*<div className="col-sm-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <ul className="list-unstyled payment-cards text-sm-right mb-0">
                  <li className="list-inline-item mr-1">
                    <Link to="#">
                      <img src={americanEx} title="American Express" alt="" />
                    </Link>
                  </li>
                  <li className="list-inline-item mr-1">
                    <Link to="#">
                      <img src={discover} title="Discover" alt="" />
                    </Link>
                  </li>
                  <li className="list-inline-item mr-1">
                    <Link to="#">
                      <img src={masterCard} title="Master Card" alt="" />
                    </Link>
                  </li>
                  <li className="list-inline-item mr-1">
                    <Link to="#">
                      <img src={paypal} title="Paypal" alt="" />
                    </Link>
                  </li>
                  <li className="list-inline-item">
                    <Link to="#">
                      <img src={visa} title="Visa" alt="" />
                    </Link>
                  </li>
                </ul>
              </div>*/}
            </div>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}

export default Footer;
